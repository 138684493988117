import { toast as _toast, TypeOptions } from "react-toastify";
import { LinkExternal } from "./link";
import { External } from "./icons/external";

export const toast = ({
	title,
	message,
	type,
	externalUrl,
}: {
	title: string;
	message: string;
	type: TypeOptions;
	externalUrl?: string;
}) => {
	_toast(
		() => (
			<div className="flex justify-between">
				<div>
					<div className="font-bold">{title}</div>
					<div className="text-tertiary max-h-[250px] overflow-x-hidden overflow-y-auto">{message}</div>
				</div>
				{externalUrl ? (
					<LinkExternal className="mt-1.5 mr-2" href={externalUrl}>
						<External width={22} height={22} />
					</LinkExternal>
				) : null}
			</div>
		),
		{
			type: type,
		}
	);
};
