import { Config, Connector, useConnect } from "wagmi";
import { Button } from "../components/button";
import {
	Dialog,
	DialogBody,
	DialogClose,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../components/dialog";
import { Close } from "../components/icons/close";
import { ElementType, useCallback, useMemo, useRef } from "react";
import {
	Coinbase,
	Frame,
	GnosisSafe,
	Ledger,
	Metamask,
	Rabby,
	TrustWallet,
	WalletConnect,
	XDEFIWallet,
} from "../components/icons/wallet";
import { ConnectData } from "wagmi/query";
import { toast } from "../components/toast";
import { shortenAddress } from "sushi/format";
import { cn } from "@/lib/utils";
import { CHAIN_ID } from "@/constants/chain-id";
import { Syringe } from "lucide-react";

const Icons: Record<string, React.ElementType> = {
	Injected: Syringe,
	MetaMask: Metamask,
	"Trust Wallet": TrustWallet,
	WalletConnect: WalletConnect,
	WalletConnectLegacy: WalletConnect,
	"Coinbase Wallet": Coinbase,
	Safe: GnosisSafe,
	Rabby: Rabby,
	Frame: Frame,
	Ledger: Ledger,
	"XDEFI Wallet": XDEFIWallet,
};

export const ConnectButton = ({ size, fullWidth }: { size: "lg" | "sm"; fullWidth?: boolean }) => {
	const { connectors, connectAsync } = useConnect();
	const closeRef = useRef<HTMLButtonElement>(null);

	const onSelect = useCallback(
		(connectorId: string) => {
			const connector = connectors.find((el) => el.id === connectorId);

			if (!connector) throw new Error("Connector not found");

			return connectAsync({
				connector,
				chainId: CHAIN_ID,
			}).then((res) => {
				closeRef.current?.click();
				toast({
					title: `${connector.name} wallet connected`,
					message: shortenAddress(res?.accounts?.[0]),
					type: "success",
				});
				return res;
			});
		},
		[connectAsync, connectors]
	);

	const _connectors = useMemo(() => {
		const conns = [...connectors];
		const _popular = ["MetaMask", "Trust Wallet", "WalletConnect", "Coinbase Wallet"];

		const popular = conns.filter((el) => _popular.includes(el.name));
		//remove duplicates from popular
		const cleanedPopular = popular.filter(
			(el, idx) => popular.findIndex((el2) => el2.name === el.name) === idx
		);

		const more = conns.filter((el) => !_popular.includes(el.name) && el.id !== "injected");

		return { popular: cleanedPopular, more };
	}, [connectors]);

	return (
		<Dialog>
			<DialogTrigger asChild className={cn(fullWidth ? "w-full" : "")}>
				{size === "lg" ? (
					<Button className="uppercase">Connect Wallet</Button>
				) : (
					<Button className="uppercase" size="sm">
						Connect
					</Button>
				)}
			</DialogTrigger>

			<DialogContent aria-describedby={undefined} className="md:max-w-[370px] ">
				<DialogHeader>
					<div className="flex justify-between items-center">
						<DialogTitle>Connect a Wallet</DialogTitle>
						<DialogClose asChild ref={closeRef}>
							<Button variant="ghost" size={"sm"}>
								<Close width={16} height={16} />
							</Button>
						</DialogClose>
					</div>
				</DialogHeader>
				<DialogBody className="flex flex-col max-h-[450px] overflow-y-auto overflow-x-hidden">
					<div className="self-start text-tertiary">Popular</div>
					{_connectors?.popular?.map((connector) => {
						const Icon = connector.name in Icons ? Icons[connector.name] : Icons.Injected;
						return <ConnectItem connector={connector} onSelect={onSelect} Icon={Icon} key={connector.id} />;
					})}
					<div className="self-start text-tertiary">More</div>
					{_connectors?.more?.map((connector) => {
						const Icon = connector.name in Icons ? Icons[connector.name] : Icons.Injected;
						return <ConnectItem connector={connector} onSelect={onSelect} Icon={Icon} key={connector.id} />;
					})}
				</DialogBody>
			</DialogContent>
		</Dialog>
	);
};

const ConnectItem = ({
	connector,
	onSelect,
	Icon,
}: {
	connector: Connector;
	onSelect: (connectorId: string) => Promise<ConnectData<Config>>;
	Icon: ElementType<any, keyof JSX.IntrinsicElements>;
}) => {
	return (
		<button
			className="flex gap-4 items-center w-full hover:bg-dialog-content p-2"
			onClick={() => onSelect(connector.id)}
			key={connector.id}>
			{connector.icon ? (
				// eslint-disable-next-line @next/next/no-img-element
				<img src={connector.icon} alt={connector.name} width={40} height={40} className="w-[40px] h-[40px]" />
			) : (
				<Icon className="w-[40px] h-[40px]" />
			)}
			{connector.name === "Safe"
				? "Gnosis Safe"
				: connector.name === "WalletConnectLegacy"
				? "WalletConnect"
				: connector.name}
		</button>
	);
};
